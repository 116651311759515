<template>
    <div class="row row-deck settings" v-if="isPageLoaded">
        <div class="col-12">
            <div class="block">
                <div class="block-header block-header-default">
                    <h3 class="block-title">Настройки</h3>
                </div>
                <div class="block-content">
                    <!-- Данные компании -->
                    <settings-form></settings-form>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="block block_cards">
                <!--<div class="default-state">
                    <div class="default-state__title">Бесплатно</div>
                    {% if global_free_mode %}
                        <div class="default-state__time">{% if data_free_mode %}до {{ data_free_mode }}{% endif %}</div>
                    {% endif %}
                </div>-->
                <div class="row">
                    <div class="col-12">
                        <div class="block-content">
                            <!-- Модули -->
                            <modules></modules>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <balance v-if="endBalance" :balance="portalInfo.balance" :count="endBalance.count"/>
        <!-- Оплата -->
        <payment></payment>
    </div>
</template>

<script>
import session from '@/api/session';
import { mapState } from 'vuex';

export default {
    name: 'Settings.vue',
    components: {
        SettingsForm: () => import('./SettingsForm'),
        Modules: () => import('./modules/Modules'),
        Payment: () => import('./payment/Payment'),
        Balance: () => import('@/components/settings/alerts/Balance')
    },
    data() {
        return {
            endBalance: null,
            isPageLoaded: false
        };
    },
    computed: {
        ...mapState('settings', [
            'portalInfo', 'rates'
        ])
    },
    async created() {
        await Promise.all([
            this.$store.dispatch('settings/setRates'),
            this.$store.dispatch('settings/initialize'),
            session.get('/api/v1/rate/end_balance/')
        ])
        .then(async ([res1, res2, res3]) => {
            const endBalance = res3.data;
            const days = +endBalance.count;
            const price = +endBalance.price;

            const balance = +this.portalInfo.balance;

            if (days === 1 || days === 2) {
                if ((balance - (price * days)) <= 0) {
                    this.endBalance = endBalance;
                }
            }

            this.isPageLoaded = true;
        })
        .catch((error) => {
            console.error(error);
        });
    }
};
</script>
<style lang="scss">
@import "#sass/v-style";
.settings {
    &__warning {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center !important;
        margin: 0 12px 25px 12px;
        padding: 20px;
        .warning {
            &__wrap {
                display: flex;
                align-items: center;
            }
            &__title {
                margin: 0 10px;
                color: #FFF;
            }
        }
    }
}
.settings .buffer {
    position: absolute;
    top: -1000px;
    left: -1000px;
    visibility: hidden;
    white-space: nowrap;
    font-size: 1.07143rem;
}

.settings .form-material__custom {
    height: 38px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-left: 1.21429rem;
    padding-right: 1.21429rem;
    border: 1px solid #e9e9e9
}

.settings .form-material__custom .form-control {
    min-width: 1px;
    padding-right: 0;
    border: 0;
    width: 25%;
    height: 34px;
    background-color: unset;
    max-width: calc(100% - 90px)!important;
    font-family: "Mulish", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    padding-left: 0;
    color: #343434;
    font-size: 1.07143rem;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-top: 0;
    padding-bottom: 0
}

.settings .form-material__custom .input-group-text {
    font-size: 1rem;
    color: #999;
    padding-left: 0;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0
}

.settings>.col-12 {
    padding-left: 12.5px;
    padding-right: 12.5px;
}

.settings .text-position {
    text-align: center
}

.settings a.btn {
    color: #000
}

.settings .btn {
    height: 2.71429rem;
    padding-left: 1.57143rem;
    padding-right: 1.57143rem;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem;
    line-height: 1;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    white-space: nowrap;
    font-weight: 700;
    color: #000;
    border: 0;
    outline: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.settings .btn-bordered {
    border-radius: 2.14286rem
}

.settings .slick-slider .slick-dots li.slick-active button {
    background-color: #fcc142
}

.settings .btn-warning {
    background-color: #fdd174
}

.settings .btn-warning:hover {
    color: #000;
    background-color: #fcc142
}

.settings .btn-warning:not([disabled]):not(.btn-disabled):active {
    background-color: #fbb110
}

.settings .btn-info {
    background-color: #e8e8e8
}

.settings .btn-circuited {
    height: 2.35714rem;
    font-size: .92857rem;
    line-height: 2.35714rem;
    background-color: transparent;
    border: .10714rem solid #fcc142
}

.settings .btn-circuited:hover {
    border-color: #fdd174
}

.settings .btn-circuited:not([disabled]):not(.btn-disabled):active {
    border-color: #fbb110
}

.settings .btn-offset {
    margin-bottom: 15px
}

.settings .block-title {
    font-size: 1.25rem;
    font-weight: 700
}

.settings .block-header-default {
    padding-top: 1.92857rem;
    padding-left: 2.14286rem;
    padding-right: 2.14286rem;
    padding-bottom: 0;
    background-color: #fff
}

.settings .block_cards .container {
    padding-left: 15px;
    padding-right: 15px
}

.settings .block_cards .row {
    margin-left: -7.5px;
    margin-right: -7.5px
}

.settings .block_cards .col,
.settings .block_cards .col-1,
.settings .block_cards .col-10,
.settings .block_cards .col-11,
.settings .block_cards .col-12,
.settings .block_cards .col-2,
.settings .block_cards .col-3,
.settings .block_cards .col-4,
.settings .block_cards .col-5,
.settings .block_cards .col-6,
.settings .block_cards .col-7,
.settings .block_cards .col-8,
.settings .block_cards .col-9,
.settings .block_cards .col-auto,
.settings .block_cards .col-lg,
.settings .block_cards .col-lg-1,
.settings .block_cards .col-lg-10,
.settings .block_cards .col-lg-11,
.settings .block_cards .col-lg-12,
.settings .block_cards .col-lg-2,
.settings .block_cards .col-lg-3,
.settings .block_cards .col-lg-4,
.settings .block_cards .col-lg-5,
.settings .block_cards .col-lg-6,
.settings .block_cards .col-lg-7,
.settings .block_cards .col-lg-8,
.settings .block_cards .col-lg-9,
.settings .block_cards .col-lg-auto,
.settings .block_cards .col-md,
.settings .block_cards .col-md-1,
.settings .block_cards .col-md-10,
.settings .block_cards .col-md-11,
.settings .block_cards .col-md-12,
.settings .block_cards .col-md-2,
.settings .block_cards .col-md-3,
.settings .block_cards .col-md-4,
.settings .block_cards .col-md-5,
.settings .block_cards .col-md-6,
.settings .block_cards .col-md-7,
.settings .block_cards .col-md-8,
.settings .block_cards .col-md-9,
.settings .block_cards .col-md-auto,
.settings .block_cards .col-sm,
.settings .block_cards .col-sm-1,
.settings .block_cards .col-sm-10,
.settings .block_cards .col-sm-11,
.settings .block_cards .col-sm-12,
.settings .block_cards .col-sm-2,
.settings .block_cards .col-sm-3,
.settings .block_cards .col-sm-4,
.settings .block_cards .col-sm-5,
.settings .block_cards .col-sm-6,
.settings .block_cards .col-sm-7,
.settings .block_cards .col-sm-8,
.settings .block_cards .col-sm-9,
.settings .block_cards .col-sm-auto,
.settings .block_cards .col-xl,
.settings .block_cards .col-xl-1,
.settings .block_cards .col-xl-10,
.settings .block_cards .col-xl-11,
.settings .block_cards .col-xl-12,
.settings .block_cards .col-xl-2,
.settings .block_cards .col-xl-3,
.settings .block_cards .col-xl-4,
.settings .block_cards .col-xl-5,
.settings .block_cards .col-xl-6,
.settings .block_cards .col-xl-7,
.settings .block_cards .col-xl-8,
.settings .block_cards .col-xl-9,
.settings .block_cards .col-xl-auto {
    padding-left: 7.5px;
    padding-right: 7.5px
}

.settings .block_cards .block-content {
    padding-bottom: 0
}

.settings .default-state {
    display: none
}

.settings .block_state {
    position: relative
}

.settings .block_state::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99
}

.settings .block_state .block-content {
    padding-top: 1.78571rem;
    padding-left: 2.14286rem;
    padding-right: 2.14286rem
}

.settings .block_state .default-state {
    position: absolute;
    top: 44%;
    left: 50%;
    width: 277.5px;
    height: 133px;
    border: 5px solid #fcc142;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 100;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #fff
}

.settings .block_state .default-state__title {
    color: #343434;
    font-size: 2rem;
    line-height: 1.33;
    letter-spacing: 3.4px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    margin-bottom: .14286rem
}

.settings .block_state .default-state__time {
    color: #343434;
    font-size: 1.14286rem;
    line-height: 1.3333;
    text-align: center
}

/* .settings .block_state>.row {
    opacity: .33
} */

.settings .block_state>.row .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
}

.settings .block_state .price-wrapper .price-header {
    background-color: #cacaca
}

.settings .block_state .price-wrapper .price-header::before {
    background-color: #999
}

.settings .block_state .price-wrapper .price-header h5 {
    color: #2d2d2d;
    text-transform: uppercase
}

.settings .block_state .price-wrapper .btn {
    background-color: #e8e8e8
}

.settings .block_state .payment {
    display: none
}

.settings .block-content {
    padding: 1.78571rem 2.14286rem 2.5rem
}

.settings .form_settings {
    .form {
        &-material {
            margin-bottom: 1.64286rem;
            padding-top: 1.78571rem;
            & > .form-control {
                border: 1px solid #e9e9e9;
                -webkit-box-shadow: none;
                box-shadow: none;
                padding-left: 20px;
                color: #343434;
                font-size: 1rem;
                &[name=domain] {
                    border-right: none;
                    & ~ .input-group-append {
                        -webkit-box-shadow: none;
                        box-shadow: none;
                        border: 1px solid #e9e9e9;
                        border-left: none;
                        .input-group-text {
                            height: 2.28571rem;
                        }
                    }
                }
            }
        }

        &-control {
            &:focus {
                & + label {
                    color: #575757;
                    font-size: 1rem;
                }
            }
        }
    }

    &__group {
        &--wall {
            max-width: 380px;
        }
    }

    &__legend {
        color: #171717;
        font-size: 18px;
        font-weight: 700;
        margin: 0 0 20px;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & + .form_settings__item {
            margin-top: 5px;
        }
    }

    &__footer {
        margin-top: 50px;
    }
}

.settings label {
    color: #999;
    font-size: 1rem;
    top: 1px
}

.settings .price-wrapper {
    position: relative;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.settings .price-wrapper_check {
    box-shadow: 0 2px 5px rgba(255, 202, 40, 1);
}

@media (min-width:768px) {
    .settings .price-wrapper {
        box-shadow: none;
    }
    .settings .price-wrapper_check {
        box-shadow: 0 2px 5px rgba(255, 202, 40, 1);
    }
    .settings .block_cards .row {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
}

@media (min-width:1366px) {
    .settings .block_cards .row {
        box-shadow: none;
    }
    .settings .price-wrapper {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
    .settings .price-wrapper_check {
        box-shadow: 0 2px 5px rgba(255, 202, 40, 1);
    }
}

.settings .price-wrapper .price-header {
    padding: 0;
    padding-top: 5.78571rem;
    background-color: #2d2d2d;
    position: relative;
    min-height: 145px
}

.settings .price-wrapper .price-header::before {
    content: '';
    position: absolute;
    height: 4px;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #fcc142
}

.settings .price-wrapper .price-header h5 {
    min-width: 100px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.14286rem;
    line-height: 1.5rem;
    letter-spacing: 1.4px;
    margin-bottom: 1.57143rem;
    color: #fff;
    text-transform: uppercase
}

.settings .price-wrapper .price-header-img-wrap {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 76px;
    width: 4.71429rem;
    margin-left: auto;
    margin-right: auto
}

.settings .price-wrapper .price-header-img-wrap img {
    display: block;
    width: 100%;
    height: auto
}

.settings .price-wrapper_active {
    background-color: #fff;
}

.settings .price-wrapper_active .price-header {
    background-color: #2d2d2d
}

.settings .price-wrapper_active .btn {
    background-color: #e8e8e8
}

.settings .price-wrapper .price-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0 5px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 1.78571rem
}

.settings .price-wrapper .price-body .price-title {
    text-align: center;
    margin-bottom: 1.5rem;
    min-height: 36px
}

.settings .price-wrapper .price-body .price-title h6 {
    color: #343434;
    font-size: 1.07143rem
}

.settings .price-wrapper .price-body .price-title h6 span {
    font-size: 1rem;
    color: #999
}

.settings .price-wrapper .price-body .price {
    font-size: 1.14286rem;
    font-weight: 700;
    color: #000;
    text-align: center;
    margin-bottom: 1.5rem
}

.settings .price-wrapper .price-body .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 125px;
    margin: 0 auto 1.5rem;
    padding: 0 0px 2px;
    font-size: 1rem;
}

.settings .price-wrapper .price-body .btn:focus {
    color: #000;
    background-color: #fdd174;
}

.settings .block_cards .carousel {
    margin: 0 -2.14286rem 0;
    padding-top: 2.5rem
}

.settings .slick-slider .slick-slider.slick-dotted {
    margin-bottom: 0
}

.settings .slick-slider .slick-slide {
    width: 200px;
    opacity: .3;
    -webkit-transition: all ease .5s;
    -o-transition: all ease .5s;
    transition: all ease .5s
}

.settings .slick-slider .slick-active,
.settings .slick-slider .slick-center {
    opacity: 1
}

.settings .slick-slider .slick-dots {
    list-style-type: none;
    margin: 0;
    margin-top: 1.14286rem;
    margin-bottom: 2.57143rem;
    padding: 0;
    width: 5.42857rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto
}

.settings .slick-slider .slick-dots li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    overflow: hidden
}

.settings .slick-slider .slick-dots li button {
    font-size: 0;
    display: block;
    border: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #e8e8e8
}

.settings .hidden-mobile {
    display: none
}

.js-block-payment {
    padding-left: 25px;
}
@media screen and (min-width:768px) {
    .settings>.col-12 {
        /*padding-left: 26px;*/
        /*padding-right: 26px;*/
    }
    .settings .text-position {
        text-align: right
    }
    .settings .btn {
        width: auto
    }
    .settings .btn-offset {
        margin-bottom: 0
    }
    .settings .btn-va {
        margin-top: 1.78571rem
    }
    .settings .btn-left {
        position: absolute;
        right: 19.07143rem;
        top: 2.14286rem
    }
    .btn-settings-pay {
        position: absolute;
        right: 2.14286rem;
        top: 2.14286rem
    }
    .settings .btn-right-bottom {
        position: absolute;
        right: 2.14286rem;
        top: 6.14286rem
    }
    .settings .block_state .default-state {
        top: 46%;
        width: 381px;
        height: 162px
    }
    .settings .block_state .default-state__title {
        font-size: 2rem;
        line-height: 1.34;
        letter-spacing: 4.4px
    }
    .settings .block_state .default-state__time {
        font-size: 1.28571rem;
        line-height: 1.3333
    }

    .settings .block_cards .carousel {
        margin: 0 -7.5px 0;
        padding-top: 0;
    }
}

@media screen and (min-width:768px) and (max-width:991px) {
    .settings .block_cards .row.carousel .col-md-3 {
        padding-left: 0;
        padding-right: 0
    }
    .settings .block_state .row.carousel .col-md-3 {
        padding-left: 7.5px;
        padding-right: 7.5px
    }
}

@media screen and (min-width:992px) {
    .settings .btn-left {
        position: static
    }
    .settings .btn-settings-pay {
        position: static
    }
    .settings .btn-right-bottom {
        position: static
    }
    .settings .hidden-mobile {
        display: block
    }
    .settings .pl-0-above-mobile {
        padding-right: 0
    }
}
@media screen and (min-width:1200px) {
    .settings .block_cards .block-content.pl-0-above-mobile {
        padding-bottom: 1.42857rem
    }

    .settings .block_state .block-content {
        padding-left: 5rem;
        padding-right: 5rem
    }

    .settings .block_state .row.carousel {
        margin-left: -1.63vw;
        margin-right: -1.63vw
    }

    .settings .block_state .row.carousel .col-xl-3 {
        padding-left: 1.63vw;
        padding-right: 1.63vw
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .content-pp .settings .block > .block-content {
        padding: 20px 30px 19px;
    }
    .content-pp > .settings {
        margin-left: 0;
        margin-right: 0;
    }
    .content-pp {
        padding: 34px 30px 1px;
    }
    .content-pp > .settings > .col-12 {
        padding-left: 0;
        padding-right: 0;
    }
    .content-pp .settings .block {
        margin-bottom: 30px;
    }
    .content-pp .settings .price-wrapper .price-header {
        padding-top: 45px;
        min-height: 120px;
    }
    .content-pp .settings .price-wrapper .price-header .price-header-img-wrap {
        width: 50px;
        bottom: 65px;
    }
    .content-pp .settings .price-wrapper .price-header h5 {
        text-align: center;
        font-size: 0.875rem;
        line-height: 17px;
        letter-spacing: 1.14px;
        margin-bottom: 0;
    }
    .content-pp .settings .price-wrapper .price-body {
        padding-top: 20px;
    }
    .content-pp .settings .price-wrapper .price-body .price-title {
        margin-bottom: 6px;
    }
    .content-pp .settings .price-wrapper .price-body .price-title h6 {
        font-size: 0.75rem;
    }
    .content-pp .settings .price-wrapper .price-body .price-title h6 span {
        font-size: 0.625rem;
    }
    .content-pp .settings .price-wrapper .price-body .price-title {
        font-size: 0.875rem;
    }
    .content-pp .settings .price-wrapper .price-body .price {
        font-size: 0.875rem;
        font-weight: 500;
    }
    .content-pp .settings .price-wrapper .price-body .btn {
        font-size: 0.875rem;
        height: 30px;
        line-height: 30px;
        padding-left: 5%;
        padding-right: 5%;
        margin-left: 14%;
        margin-right: 14%;
    }
    .content-pp .settings .block_state .block-content {
        padding-top: 34px;
    }
    .content-pp .settings .block_state .default-state {
        top: 52%;
    }
    .content-pp .settings .block_state .default-state__title {
        font-size: 2rem;
        font-weight: 600;
        letter-spacing: 3.4px;
    }
}
@media screen and (min-width: 1200px) {
    .content-pp {
        padding: 27px 27px 1px 23px;
    }
    .content-pp .settings .block-header-default {
        padding-top: 29px;
    }
    .content-pp .settings .block > .block-content {
        padding: 14px 34px 35px 30px;
    }
    .content-pp .settings .form_settings .form-material {
        margin-bottom: 23px;
    }
    .content-pp .settings .block {
        margin-bottom: 25px;
    }
    .content-pp .settings .block_state .block-content {
        padding-left: 72px;
        padding-right: 83px;
        padding-bottom: 2px;
    }
    .content-pp .settings .price-wrapper .price-body {
        padding-top: 28px;
    }
    .content-pp .settings .price-wrapper .price-body .price-title {
        margin-bottom: 17px;
    }
    .content-pp .settings .price-wrapper .price-body .price {
        margin-bottom: 34px;
    }
    .content-pp .settings .price-wrapper .price-body .btn {
        margin-bottom: 25px;
    }
    .content-pp .settings .block_state .default-state__title {
        letter-spacing: 2.5px;
    }
}
@media screen and (max-width: 767px) {
    .content-pp {
        padding: 13px 15px 1px;
    }
    .content-pp .settings .block-header-default {
        padding: 25px 25px 0;
    }
    .content-pp .settings .block > .block-content {
        padding: 18px 25px 21px;
    }
    .content-pp .settings .block {
        margin-bottom: 32px;
    }
    .content-pp .settings .block-content .block {
        max-width: 200px;
        margin: 0 auto 20px;
    }
    .content-pp .settings .btn {
        font-weight: 500;
        font-size: 1rem;
        margin-top: 10px;
    }
    .content-pp .settings .block_state .block-content {
        padding-top: 25px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 2px;
    }
    /* .content-pp .settings .block_state::before {
        pointer-events: none;
    }
    .content-pp .settings .block_state .default-state {
        pointer-events: none;
    } */
    .content-pp .settings .carousel {
        padding-top: 9px;
    }
    .content-pp .settings .slick-slider {
        margin-bottom: 21px;
    }
    .content-pp .settings .slick-slider .slick-dots {
        /* display: none; */
    }
}

.fix_width{
    width: 106%;
}

.portal_blocked{
    width: 180px;
    color: red;
}

@media only screen and (max-width: 768px) {
    .block_state .slick-list {
        padding: 0;
    }
}

.settings .btn-settings-pay {
    margin-top: 20px;
    height: 34px;
    padding-left: 28px;
    padding-right: 28px;
    font-size: 1rem;
    line-height: 31px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    white-space: nowrap;
    font-weight: 700;
    border: 1.5px solid #fcc142;
    border-radius: 17px;
    color: #000;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* * * * * new styles for rate-info  * * * * */
/* mobile first */

.rate-info {
    padding: 25px;
    &__row {
        margin: -10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media screen and (min-width: 1281px) {
            align-items: flex-start;
            flex-direction: row;
        }
    }

    &__col {
        margin: 10px;
        flex-grow: 1;
        @media screen and (min-width: 1440px) {
            flex-basis: calc((100% / 3) - 20px);
        }
    }

    &__payments {
        list-style: none;
        padding: 0;
        margin-top: 5px;
        &-item {
            &:nth-child(n+2) {
                margin-top: 10px;
            }
        }
    }
}

.rate-info__item {
    margin-bottom: 26px;
    white-space: nowrap;
    /*max-width: 155px;*/
}

.rate-info__label {
    margin-bottom: 7px;
    font-size: 1.25rem;
    line-height: 1.25;
    color: #999;
    font-weight: 300;
    white-space: pre-wrap;
}

@media (max-width: 1365px) {
    .rate-info__label {
        margin-bottom: 14px;
    }
}

.rate-info__value {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    color: #000;
}

.rate-info__action {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.rate-info__close {
    max-width: 180px;
    font-size: 1rem;
    font-weight: 500;
    color: #d0021b;
    padding-right: 40px;
    background: 100% center / 38px auto url("/static/img/settings/lock.png") no-repeat;
    white-space: normal;
}

.rate-info__btn {
    margin-bottom: 15px;
    width: 100%;
    display: inline-block;
    min-width: 140px;
    padding: 10px 18px 11.5px;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    color: #000;
    text-align: center;
    border: 2px solid #fcc142;
    border-radius: 40px;
    background: transparent;
    cursor: pointer;
}

.rate-info__btn:hover {
    color: #000;
    background: #fcc142;
}

.rate-info__btn_wide {
    min-width: 249px;
}

.rate-info__btn--gold {
    background: #fcc142
}
@media (min-width: 768px) {
    .rate-info {
        padding: 27px 30px 35px;
    }

    .rate-info__item {
        padding: 0;
    }

    .rate-info__desc {
        display: flex;
        flex-direction: column;
    }

    .rate-info__table,
    .rate-info__status {
        display: flex;
        justify-content: space-between;
    }

    /*.rate-info__status {
        margin-left: -25px;
    }*/

    .rate-info__action {
        flex-direction: row;
    }

    .rate-info__btn {
        width: auto;
        margin-right: 25px;
        margin-bottom: 0;
    }

    .rate-info__btn_wide {
        min-width: 238px;
    }
}

@media (min-width: 1366px) {

    .rate-info {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .rate-info__title {
        flex: 0 0 auto;
    }

    .rate-info__item {
        width: 100%;
        margin-bottom: 30px;
        &:first-child {
            margin-bottom: 20px;
        }
    }

    .rate-info__item:last-of-type {
        margin-bottom: 0;
    }

    .rate-info__table, .rate-info__status {
        flex-direction: column;
        width: 100%;
        padding-left: 2px;
    }

    .rate-info__action {
        margin-top: auto;
        justify-content: space-between;
    }

    .rate-info__btn {
        margin-right: 0;
    }

    .rate-info__status {
        padding-left: 5px;
        border-left: 1px solid #e8e8e8;
    }

    .rate-info__btn_wide {
        min-width: 230px;
    }

}

@media (min-width: 992px) {
    /* тень при наведении только на десктопе #29933 */
    .block.block-link-pop:hover {
        box-shadow: 0 2px 30px #d8dde5;
        -webkit-transform: translateY(-3px);
        transform: translateY(-3px);
        opacity: 1;
    }
}

.block.block-link-pop {
    transition: all .12s ease-out;
}

.system-module {
    padding-top: 20px;
}

.system-module__title {
    margin-bottom: 15px;
}

.system-module__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0 -25px;
    padding: 0;
    padding-bottom: 15px;
}

.system-module__item {
    padding: 0 25px;
}

.system-module__item-wrap {
    padding: 22px 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #e8e8e8;
}

.system-module__item:nth-last-child(1) .system-module__item-wrap {
    border-bottom: none;
}

.system-module__name-wrap {
    position: relative;
    @media (min-width: 768px) {
        flex-basis: 73%;
    }
}

.settings  .system-module__ctrl {
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.system-module__name-wrap_recommend + .system-module__cost {
    margin-bottom: 20px;
}

.settings .system-module__set-btn {
    display: flex;
    width: 17px;
    height: 17px;
    margin-top: 0;
    margin-left: 5px;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.settings .system-module__set-btn svg {
    pointer-events: none;
}

.system-module__name-wrap_recommend::before {
    content: 'Рекомендуем';
    position: absolute;
    display: flex;
    align-self: flex-start;
    padding-left: 8px;
    width: 92px;
    height: 20px;
    bottom: -42px;
    font-family: inherit;
    color: #000;
    font-size: 0.75rem;
    line-height: 1.1;
    font-weight: 600;
    background-image: url(/static/img/settings/rectangle-orange.png);
    background-repeat: no-repeat;
    background-position-y: center;
    background-size: 92px 20px;
}

.system-module__desc-btn {
    flex: 0 0 auto;
    margin-left: 10px;
}

.system-module__name {
    display: inline;
    color: #000000;
    font-size: 1rem;
}

.system-module__cost {
    align-items: center;
    margin-right: auto;
    color: #000000;
    font-size: 1rem;
    font-weight: 600;
}

.system-module__desc-btn {
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
    text-align: center;
    border: 0;
    outline: 0;
    padding: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: transparent;
    background-image: url("/static/img/settings/info-icon-active.png");
    background-image: none;
    background-image: url("/static/img/settings/info-icon.png");
    background-size: 16px 16px;
    background-repeat: no-repeat;
    cursor: pointer;
}

.system-module__desc-btn:hover {
    background-image: url("/static/img/settings/info-icon-active.png");
}

.css-switch.css-control-primary .css-control-input:checked ~ .css-control-indicator {
    background-color: #fcc142;
}

.css-switch.css-control-primary .css-control-input:disabled ~ .css-control-indicator {
    opacity: 0.4;
}

@media (min-width: 768px) {

    .block.block-link-pop {
        margin-bottom: 0;
    }

    .system-module {
        padding-top: 40px;
    }

    .system-module__item-wrap {
        flex-wrap: nowrap;
        align-items: center;
        height: 100%;
    }

    .system-module__name-wrap {
        margin-right: 20px;
        display: flex;
        /*margin-right: 120px;*/
        /*width: auto;*/
    }

    .system-module__cost {
        margin-right: 12px;
        margin-left: auto;
        text-align: right;
        max-width: 80%;
    }

    .system-module__cost.paid {
        white-space: nowrap;
    }

    .settings .system-module__ctrl {
        position: static;
        right: auto;
        top: auto;
        transform: none;
        align-items: center;
        width: auto;
    }

    .system-module__name-wrap_recommend + .system-module__cost {
        margin-bottom: 0;
    }

    .system-module__item--unavailable .system-module__name-wrap_recommend::before {
        right: -35px;
    }

    .system-module__name-wrap_recommend::before {
        right: -101px;
        left: auto;
        bottom: auto;
        top: -2px;
        height: 100%;
    }
}

@media (min-width: 1366px) {
    .system-module__list {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .system-module__item {
        width: 50%;
    }

    .system-module__item:nth-last-child(2) .system-module__item-wrap {
        border-bottom: none;
    }
}

.payments-detal__title {
    margin-bottom: 19px;
}

.payments-detal__wrap {
    padding: 25px;
}

.payments-detal__list {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
}

.payments-detal__date {
    margin-right: 10px;
}

.payments-detal__name {
    margin-bottom: 5px;
    color: #000000;
    font-size: 1rem;
}

.payments-detal__item-wrap {
    padding: 16px 0;
    border-bottom: 1px solid #e8e8e8;
}
.payments-detal__item:nth-last-child(1) .payments-detal__item-wrap {
    border-bottom: none;
}

.payments-detal__date {
    font-size: 0.75rem;
}

.payments-detal__amount {
    color: #000000;
    font-size: 0.75rem;
    font-weight: 400;
}

.payments-detal__btn {
    width: 100%;
    margin-bottom: 15px;
    display: inline-block;
    min-width: 140px;
    padding: 10px 18px 11.5px;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    color: #000;
    text-align: center;
    border: 2px solid #fcc142;
    border-radius: 40px;
    background: transparent;
    cursor: pointer;
}

.payments-detal__btn:hover {
    background: #fcc142;
    color: #000;
}

@media (min-width: 768px) {
    .payments-detal__item-wrap {
        display: flex;
        align-items: center;
    }

    .payments-detal__name {
        margin-right: 12px;
        margin-bottom: 0;
        flex: 0 0 auto;
    }

    .payments-detal__pay-wrap {
        flex: 1 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    .payments-detal__amount {
        font-size: 1rem;
    }

    .payments-detal__btn {
        width: auto;
    }
}

@media (min-width: 1366px) {
    .payments-detal__wrap {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .payments-detal__title {
        flex: 0 0 auto;
    }

    .payments-detal__btn {
        margin-top: auto;
        align-self: flex-start;
    }
}

/* Описание модуля Модалка */
.module-description__carousel {
    max-height: 355px;
    overflow: hidden;
}

.module-description__carousel .slick-dots {
    list-style-type: none;
    margin: 0;
    margin-top: 1.14286rem;
    margin-bottom: 2.57143rem;
    padding: 0;
    width: 5.42857rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
}

.module-description__carousel .slick-dots li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    overflow: hidden;
}

.module-description__carousel .slick-dots li button {
    font-size: 0;
    display: block;
    border: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #e8e8e8;
    cursor: pointer;
}

.module-description__carousel .slick-dots li.slick-active button {
    background-color: #fcc142;
}

.module-description__top-text {
    margin-bottom: 30px;
}

.module-description__slide-img {
    width: 100%;
    padding-bottom: 20px;
}

.module-description__title {
    color: #000000;
    font-size: 1.25rem;
    font-weight: 600;
}

.modal__close-btn {
    position: absolute;
    top: 20px;
    right: 25px;
    background-color: transparent;
    border: none;
    font-size: 0;
    width: 17px;
    height: 17px;
    background-image: url("/static/img/settings/close-cross.png");
    cursor: pointer;
}

.module-description__header {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 45px 0;
}

.module-description__body {
    padding: 0 20px;
}

.module-description__footer {
    display: flex;
    justify-content: center;
    padding: 45px 0;
}

.module-description__pay-btn {
    margin-bottom: 15px;
    display: inline-block;
    min-width: 140px;
    padding: 10px 18px 11.5px;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    color: #000;
    text-align: center;
    border: none;
    border-radius: 40px;
    background: #fcc142;
    cursor: pointer;
}

@media (min-width: 768px) {
    .module-description__title {
        width: 100%;
        text-align: center;
    }

    .module-description__inner {
        max-width: 710px;
    }

    .module-description__body {
        padding: 0 45px;
    }
}

@media (min-width: 1366px) {
    .module-description__body {
        padding: 0 70px;
    }
}

/* Настройки модуля Модалка */
.modal-dialog {
    margin: 0.5rem auto;
}

.module-set__inner {
    width: 306px;
}

.module-set__header {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 35px 15px 20px;
    border-bottom: 1px solid #e8e8e8;
}

.module-set__body {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}

.module-set__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0 35px;
}
.module-set__footer_left {
    justify-content: left;
}
.module-set__title {
    width: 100%;
    font-size: 1.25rem;
}
.module-set__subtitle {
    margin-top: 30px;
    width: 100%;
    font-size: 1rem;
}
.module-set__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
}
.module-set__item {
    margin-top: 25px;
    display: flex;
    align-items: center;
    width: 100%;
}
.module-set__pers {
    width: 240px;
}
.module-set__del-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    padding: 0;
    width: 21px;
    height: 21px;
    border: none;
    background-color: #e8e8e8;
    border-radius: 50%;
    cursor: pointer;
}
.module-set__del-btn:hover {
    background-color: #ddd;
    fill: #000;
}
.module-set__del-btn:hover svg path {
    fill: inherit;
}
.module-set__save-btn,
.module-set__cancel-btn {
    display: inline-block;
    min-width: 140px;
    margin-left: 20px;
    padding: 10px 18px 11.5px;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    color: #fff;
    text-align: center;
    border: none;
    border-radius: 40px;
    background: #fcc142;
    cursor: pointer;
}
.module-set__save-btn:first-child,
.module-set__cancel-btn:first-child {
    margin-left: 0;
}
.module-set__cancel-btn {
    background: none;
    border: 1px solid #e9e9e9;
    color: #000;
}
.module-set__add-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    height: 38px;
    width: 240px;
    background-color: transparent;
    border: 1px dashed #e8e8e8;
    border-radius: 3px;
    cursor: pointer;
}
.module-set__add-btn:hover {
    border: 1px dashed #ccc;
}
.module-set__cansel-btn {
    display: inline-block;
    padding: 10px 18px 11.5px;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    color: #999;
    text-align: center;
    border: none;
    border-radius: 40px;
    background-color: transparent;
    cursor: pointer;
}
.module-set__cansel-btn:hover {
    color: #000;
    background-color: rgb(221, 221, 221);
}

@media (min-width: 768px) {
    .module-set__inner {
        width: 540px;
    }
    .module-set__header {
        padding: 35px 40px 20px;
    }
    .module-set__body {
        padding: 0 40px;
    }
    .module-set__add-btn,
    .module-set__pers {
        width: 434px;
    }
}

/* период оплаты */
.hidden-ctrl {
    position: absolute;
    clip: rect(0 0 0 0);
    padding: 0;
    height: 1px;
    width: 1px;
    margin: -1px;
    border: 0;
    overflow: hidden;
}
.payment__nav {
    display: flex;
    width: 100%;
    max-width: 460px;
    list-style: none;
    padding: 0;
}

.payment__nav-item {
    flex: 0 0 25%;
    display: flex;
}

.settings  .payment__nav-label {
    flex: 0 0 auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px;
    font-size: 0.875rem;
    line-height: 1.2;
    border: 1px solid #e8e8e8;
    cursor: pointer;
}

.payment__nav-discount {
    width: 100%;
    color: #fcc142;
}

.payment__nav-item:first-child .payment__nav-label {
    padding-left: 25px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.payment__nav-item:last-child .payment__nav-label {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.payment__nav-ctrl:checked + .payment__nav-label {
    color: #000;
    border: 1px solid #fcc142;
}

@media (max-width: 490px) {
    .settings {
        &__warning {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    .warning {
        &__icon {
            display: none;
        }
        &__btn {
            margin-top: 20px;
        }
    }
    .payment__nav {
        margin-left: -5px;
        margin-right: -5px;
    }
    .settings .payment__nav-label {
        padding: 10px 5px 8px;
        font-size: 9px;
    }
    .payment__nav-item:first-child .payment__nav-label {
        padding-left: 15px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }

    .payment__nav-item:last-child .payment__nav-label {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    .rate-info__title {
        width: 100%;
    }
}

.settings .block_state::before {
    bottom: 40%;
    background-color: rgba(255, 255, 255, 0.67);
}

.settings .block_state .default-state {
    top: 30%;
}

.settings .block_state>.row {
    opacity: 1;
}

@media (max-width: 1365px) {
    .settings .block_state::before {
        bottom: 55%;
    }
    .rate-info__desc {
        flex-direction: column;
    }
    .rate-info__table {
        flex-direction: column;
    }
    .rate-info__status {
        flex-direction: column;
    }
    .settings .block_state .default-state {
        top: 24%;
    }
}

@media (max-width: 767px) {
    .system-module {
        &__item {
            &-col {
                &--right {
                    flex-basis: 100%;
                }
            }
        }

        &__switch {
            width: 100%;
            justify-content: space-between;
        }
    }
    .settings .block_state::before {
        bottom: 60%;
    }
    .rate-info__btn {
        width: auto;
        margin-bottom: 0;
    }
    .js-block-payment {
        padding-left: 0;
    }
    .settings .block_state .default-state {
        top: 21%;
    }
}

.pl-25 {
    padding-left: 25px;
}
</style>
